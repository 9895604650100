import React, { useState, useEffect } from "react";
import View from './view';
import Element from './element';
import Toggle from './toggle';
import utils from "./utils";
import CurrencyTextField from "@unicef/material-ui-currency-textfield/dist/CurrencyTextField";
import Button from '@material-ui/core/Button';
import Anotacao from "./anotacao";

function Pedido() {
  let defaults = {
    id_filial_entrega: 0,
    id_pedido: 0,
    retirada_terceiro_nome: '',
    retirada_terceiro_cpf: '',
    valor_frete: 0,
    valor_credito: 0,
    valor_descontos: 0,
    valor_total: 0,
    valor_spv: 0,
    valor_produtos: 0,
    usa_spv: false,
    exibir_spv: true,
    parcela: 1
  };

  let [entrega, setEntrega] = useState({});
  let [descontos, setDescontos] = useState({ valor_desconto_spv: 0, valor_desconto_pedido: 0 });
  let [view, setView] = useState({});
  let [pedido, setPedido] = useState(defaults);
  let [pagamentos, setPagamentos] = useState([]);
  let [meusPagamentos, setMeusPagamentos] = useState([]);
  let [formasEntrega, setFormasEntrega] = useState([]);
  let [crud, setCrud] = useState({ data: defaults, changed: defaults, requireds: {} });
  let [ready, setReady] = useState(false);
  let [empty, setEmpty] = useState(false);
  let [index, setIndex] = useState(0);
  let idPedido = 0;
  let create = false;
  let [access, setAccess] = useState(window.access ? window.access : {});
  let user = utils.getUser();
  let tipos_pedido = [
    {
      descricao: 'VENDA FUTURA',
      constante: 'VENDA_FUTURA'
    }
  ];

  window.processo_atual = 73;

  crud.refresh = function () {
    setCrud(crud);
    setIndex(index + 1);
  }

  crud.allow = function (key) {
    if (user.administrativo) {
      return true;
    }
    return window.access[key];
  }

  crud.open = function (value, args) {
    const click = value.click;
    const data = args.data;
    let d = {};
    let url = click.url;

    for (const i in data) {
      const v = data[i];
      const b = '{' + i + '}';
      const c = v + '';

      url = url.replace(b, c);
    }
    d.path = url;
    d.label = click.label;

    window.location.href = url;
  }

  const listarInformacaoPgto = function (p) {
    utils.execute(crud, 'listarInformacaoPgto?id_pedido=' + p.id_pedido, null, function (res) {
      const infos = {};

      for (const i in res.data) {
        const o = res.data[i];

        infos[o.chave] = o.valor;
      }
      p.infos = infos;
    });
  }

  const listarFormaEntregas = function (p) {

    const _ks_ = {};

    _ks_.crud = crud;
    _ks_.changed = {
      id_pedido: p.id_pedido
    };
    _ks_.element = {};
    _ks_.alert = false;
    _ks_.global = true;

    _ks_.complete = function (res) {
      formasEntrega = res.data;

      for (const i in formasEntrega) {
        let frete = formasEntrega[i];

        frete.indisponivel = false;

        if (res.error) {
          frete.indisponivel = true;
        }

        if (frete) {
          let unidadeTempo = frete.unidade_tempo ? frete.unidade_tempo : 'dias_uteis';
          frete.valor_frete = frete.preco;
          // Mensagem retirada da tela, é possível que queiram reativar no futuro
          frete.previsao = '';
          // frete.previsao = frete.prazo > 0 ? utils.lng('chegará_em', 'Chegará em') + ' ' + frete.prazo + ' ' + utils.lng(unidadeTempo, 'dias úteis') : '';
          frete.erro = frete.erro;
        }
      }
      setFormasEntrega(res.data);
    };

    _ks_.cfg = {
      url: 'cart/calculateShipping',
      alert: false,
      method: 'POST'
    };
    utils.call(_ks_);

  }

  const refreshItens = function () {
    refreshPedido();

    if (typeof itensCarrinho !== 'undefined') {
      window.itensCarrinho.search();
    }
  }

  const carregarPagamentos = function (p) {
    utils.execute(crud, 'listar_pagamentos_pedido?id_pedido=' + p.id_pedido, null, function (res) {
      meusPagamentos = [];

      if (res.data) {
        meusPagamentos = res.data;
        setMeusPagamentos(res.data);
      }
    });
  }

  const refreshPedido = function () {
    utils.execute(crud, 'encontrarPedido?id_pedido=' + idPedido, null, function (res) {
      setEmpty(true);

      for (const i in res.data) {
        setEmpty(false);

        const p = res.data[i];

        p.cliente = {};
        p.cliente.nome = p.nome_cliente;
        p.cliente.id = p.id_cliente;

        p.filialRetirada = {};

        if (p.id_filial_retirada > 0) {
          p.filialRetirada.id = p.id_filial_retirada;
          p.filialRetirada.nome = p.nome_filial_retirada;
        } else {
          p.filialRetirada.id = p.id_filial;
          p.filialRetirada.nome = p.filial;
        }

        if (p.valor_desconto_pedido > 0) {
          descontos.valor_desconto_pedido = p.valor_desconto_pedido;
        }

        if (p.valor_desconto_spv > 0) {
          descontos.valor_desconto_spv = p.valor_desconto_spv;
        }

        p.parcela = 1;

        listarInformacaoPgto(p);
        carregarPagamentos(p);

        if (p.constante_status === 'COMPRANDO') {
          listarFormaEntregas(p);
        }
        setPedido(p);
      }
      if (res.data.length <= 0) {
        window.location.href = '/meus_pedidos';
      }
    });
  }

  if (!ready) {
    setReady(true)

    utils.execute(crud, 'HubCondicaoPagamento', ['id', 'codigo', 'nome'], function (res) {
      setPagamentos(res.data);
    });


    const path = window.location.pathname;
    const split = path.split('/');

    if (split.length > 3) {
      try {
        idPedido = parseInt(split[3]);

        if (idPedido === 0) {
          create = true;
        }
        if (!(idPedido > 0)) {
          idPedido = 0;
        }
      } catch (x) {
      }
    }

    if (create) {
      const _ks_ = {};

      _ks_.crud = crud;
      _ks_.changed = {};
      _ks_.element = {};

      _ks_.complete = function (res) {
        refreshPedido();
      };

      _ks_.cfg = {
        url: 'cart/create',
        method: 'POST'
      };
      utils.call(_ks_);
    } else {
      refreshPedido();
    }
  }

  crud.refreshPedido = refreshPedido;
  crud.selecionarCliente = function (a, b) {

    pedido.nome_cliente = b.cliente.nome;
    pedido.id_cliente = b.cliente.id;
    pedido.codigo_mundial = b.cliente.codigo;

    setIndex(index + 1);

    const _ks_ = {};

    _ks_.crud = crud;
    _ks_.changed = pedido;
    _ks_.element = {};

    _ks_.complete = function (res) {
      refreshPedido();
      crud.refresh();
    };

    _ks_.cfg = {
      url: 'cart/changeClient',
      method: 'POST'
    };
    utils.call(_ks_);
  };

  crud.onsearch = function (el, args) {
    crud.changed.query = args.query;
  }


  const selecionarTipoVenda = function (b) {
    if (pedido.id_pedido > 0) {
      const _ks_ = {};

      _ks_.global = true;
      _ks_.crud = crud;
      _ks_.changed = {
        tipo: b.constante
      };
      _ks_.element = {};

      _ks_.complete = function (res) {
        if (crud) {
          setIndex(index + 1);
          refreshPedido();
        }
      };

      _ks_.cfg = {
        alert: false,
        url: 'cart/changeType',
        method: 'POST'
      };
      utils.call(_ks_);
    }
  }


  const aplicarDescontos = function (b) {
    if (pedido.id_pedido > 0) {
      const _ks_ = {};

      _ks_.global = true;
      _ks_.crud = crud;
      _ks_.changed = descontos;
      _ks_.element = {};

      _ks_.complete = function (res) {
        if (crud) {
          setIndex(index + 1);
          refreshPedido();
        }
      };

      _ks_.cfg = {
        alert: false,
        url: 'cart/complement',
        method: 'POST'
      };
      utils.call(_ks_);
    }
  }

  const onChangePercentualDesconto = function (target, key, val) {
    let base = pedido[target];
    let calc = base * val / 100;

    descontos[key] = calc.toFixed(2);

    setIndex(index + 1);
  };

  const onChangeDesconto = function (key, val) {
    descontos[key] = val;

    descontos.percentual_desconto_pedido = 0;
    descontos.percentual_desconto_spv = 0;

    setIndex(index + 1);
  };

  const selecionarEntrega = function (b) {
    if (pedido.id_pedido > 0) {
      if (b.erro != null && b.erro != '') {
        let actions = [];
        actions.push({ label: utils.lng('confirmar', 'Confirmar'), action: () => {} })

        if (window.dialog) {
          window.dialog.alert(b.erro, null, actions);
        }
      } else {
        pedido.id_entrega = b.id_entrega;
        pedido.constante_entrega = b.constante;
        setIndex(index + 1);
        setEntrega(b);

        const _ks_ = {};

        _ks_.global = true;
        _ks_.crud = crud;
        _ks_.changed = pedido;
        _ks_.element = {};

        _ks_.complete = function (res) {
          if (!res.error) {
            window.location.reload();
          }
        };

        _ks_.global = true;
        _ks_.cfg = {
          alert: false,
          global: true,
          url: 'cart/changeDelivery',
          method: 'POST',
          global: true
        };
        utils.call(_ks_);
      }
    }
  }

  crud.consultarCEP = function (el, args) {
    const _ks_ = {};

    _ks_.crud = {};
    _ks_.changed = { cep: pedido.cep };
    _ks_.element = {};

    _ks_.complete = function (res) {
      if (res.data) {
        const p = res.data;

        for (const i in res.data) {
          if (typeof res.data[i] !== 'object') {
            pedido[i] = res.data[i];
          }
        }
        setPedido(pedido);
        setIndex(index + 1);
      }
    };

    _ks_.cfg = {
      alert: false,
      url: 'consultarCEP',
      method: 'GET'
    };
    utils.call(_ks_);
  };

  crud.selecionarProduto = function (el, args) {
    const sku = args.produto;

    if (sku) {
      const _ks_ = {};

      _ks_.crud = {};
      _ks_.global = true;
      _ks_.changed = { quantidade: 1 };
      _ks_.element = {};

      _ks_.complete = function (res) {
        refreshPedido();

        if (window.itensCarrinho) {
          window.itensCarrinho.search();
        }
      };

      _ks_.cfg = {
        url: 'cart/changeProduct/' + sku,
        global: true
      };
      utils.call(_ks_);
    }
  }

  const terceiro = {
    name: 'terceiro',
    elements: {
      nome_pessoa_retirar: { type: 'text', label: 'Nome', cols: 12, required: true },
      documento_pessoa_retirar: { type: 'text', label: 'Documento', cols: 12, required: true },
      dh_retirada: { type: 'date', label: 'Data retirada', cols: 12, required: true },
      ok: {
        type: 'button', label: 'OK', cols: 2,
        styleClass: 'ui-input-button',
        rendered: false,
        click: {
          url: 'cart/changeDelivery',
          method: 'POST',
          params: {
            tipoEntrega: 'RETIRAR_FILIAL'
          }
        }
      }
    }
  };

  const acoes_topo = {
    space: 6,
    elements: {
      orcamento: {
        type: 'button',
        label: 'Gerar pedido',
        space: 3,
        rendered: pedido.constante_status === 'ORCAMENTO',
        style: { marginLeft: '20px' },
        click: {
          prompt: 'Pedidos em aberto serão encerados, deseja continuar?',
          url: 'cart/{id_pedido}/continue',
          method: 'PUT',
          redirect: '/carrinho/pedido'
        }
      },
      convite: {
        type: 'button',
        label: 'Gerar Convite',
        rendered: pedido.convite === true,
        disabled: "${@this.constante_status === 'AGUARDANDO_PAGAMENTO'}",
        space: 3,
        style: { marginLeft: '20px' },
        click: {
          url: 'convite_convensao/print/{id_pedido}.pdf',
          method: 'GET',
          download: true,
          fileName: 'convite.pdf',
          send: false
        }
      }
    }
  };


  const endereco = {
    name: 'endereco',
    disabled: true,
    elements: {
      cep: { type: 'text', mask: '99.999-999', required: true, label: 'CEP', cols: 3, min: 8, change: { action: 'consultarCEP' } },
      bairro: { type: 'text', label: 'Bairro', cols: 6, required: true },
      numero: { type: 'text', label: 'Número', cols: 3, required: true },
      cidade: { type: 'text', label: 'Cidade', cols: 6, required: true },
      estado: { type: 'text', label: 'Estado', cols: 6, required: true },
      logradouro: { type: 'text', label: 'Rua', cols: 6, required: true },
      complemento: { type: 'text', label: 'Complemento', cols: 6, required: true },
      ok: {
        rendered: false,
        type: 'button', label: 'Ok', cols: 2,
        click: {
          url: 'cart/changeDeliveryAddress',
          redirect: true,
          method: 'POST'
        }
      }
    }
  };
  const confirmar = {
    name: 'irPagamento',
    style: { float: 'right' },
    elements: {
      confirmar_pagamento: {
        rendered: pedido.quantidade > 0,
        disabled: "${!exists(@this,'constante_entrega')}",
        theme: 'success',
        type: 'button', label: utils.lng('confirmar_pagamento', 'Selecionar pagamento'),
        click: {
          url: 'cart/cartValidate',
          method: 'POST',
          redirect: '/carrinho/pagamento'
        }
      }
    }
  };

  const filial = {
    name: 'filialRetirada',
    id: 'filialRetirada',
    label: utils.lng('filial_retirada', 'Filial retirada'),
    type: 'entity',
    add: false,
    trash: false,
    readyOnly: true,
    disabled: "${crud.allow('EDITAR_FILIAL') === false}",
    showLabel: false,
    entity: 'Filial',
    change: {
      url: 'cart/changeDeliveryLocal',
      method: 'POST',
      redirect: true,
      global: true
    }
  };

  const cliente = {
    name: 'cliente',
    id: 'cliente',
    selectLabel: utils.lng('btn_selecione', 'SELECIONE'),
    label: utils.lng('nome_cliente', 'Cliente'),
    type: 'entity',
    disabled: "${!crud.allow('EDITAR_CLIENTE')}",
    add: false,
    trash: false,
    entity: 'Cliente',
    list: {
      url: 'listarClientesCarrinho'
    },
    change: {
      global: true,
      action: 'selecionarCliente'
    }
  };

  const itens = {

    elements: {
      itens_search: {
        style: { paddingBottom: '10px' },
        elements: {
          produto: {
            name: 'produto',
            size: 50,
            auto: false,
            cols: 3,
            widgetVar: 'itensCarrinho',
            label: pedido.id_cliente === undefined ? utils.lng('pesquisar_selecione_cliente', 'Pesquisar (Selecione um cliente)') : utils.lng('pesquisar', 'Pesquisar'),
            disabled: pedido.id_cliente === undefined,
            itemLabel: 'pesquisa',
            itemValue: 'id_sku',
            inputIcon: 'fa fa-search',
            styleClass: 'ui-search',
            fields: ["pesquisa"],
            type: pedido.id_cliente === undefined ? 'text' : 'complete',
            columns: {
              avatar: { type: 'img' },
              descricao: {},
              valor: { type: 'span', format: 'money', field: 'valor', style: { paddingBottom: '10px', color: '#E2E9F0', fontSize: '20px', fontWeight: 'bold' } }
            },
            list: {
              url: 'listarProdutosCarrinho'
            },
            change: {
              action: 'selecionarProduto'
            },
            keypress: {
              action: 'onsearch'
            }
          },
          acoes_carrinho: {
            cols: 9,
            layout: 'inline',
            style: { textAling: 'right' },
            elements: {
              ver_orcamentos: {
                rendered: (user.tipo+'').indexOf('ADMINISTRATIVO') > -1,
                disabled: pedido.id_cliente === undefined,
                type: 'button',
                label: utils.lng('ver_orcamento', 'VER ORÇAMENTOS'),
                click: {
                  redirect: '/orcamentos_cliente/' + pedido.id_cliente,
                  validate: false
                }
              },
              limpar_carrinho: {
                type: 'button',
                label: utils.lng('cancela_ped', 'CANCELAR PEDIDO'),
                theme: 'secondary',
                click: {
                  prompt: utils.lng('prompt_cancel_pedido', 'Tem certeza que deseja cancelar seu pedido?'),
                  url: 'cart/cancel',
                  method: 'POST',
                  validate: false
                }
              },
              ir_catalogo: {
                type: 'button',
                label: 'Acessar catálogo',
                click: {
                  validate: false,
                  redirect: '/carrinho'
                }
              }
            }
          }
        }
      },
      itens_grid: {
        name: 'itens',
        type: 'list',
        layout: 'table',
        search: false,
        widgetVar: 'itensCarrinho',
        controls: false,
        size: 500,
        add: false,
        list: {
          url: 'listarItensCarrinho?id_pedido={id_pedido}',
          send: false
        },
        columns: {
          avatar: {
            upload: false, type: 'img', sort: false, click: {
              redirect: '/carrinho/produto/${@this.id_sku}'
            }
          },
          info: {
            label: utils.lng('descricao', 'Descrição'), sort: false,
            elements: {
              descricao: { field: 'descricao', type: 'span' },
              detalhe: { field: 'detalhe', type: 'span', sort: false },
            }
          },
          quantidade: {
            label: 'Quantidade', rowEdit: true, type: 'spinner2', disabled: '${!@this.permite_editar_item}', numeric: true, click: false, change: {
              url: 'cart/changeProduct/{id_sku}',
              method: 'GET',
              rollback: true,
              complete: refreshPedido
            }, sort: false
          },
          valor_total_itens: {
            label: utils.lng('valor_item_carrinho', 'Valor'),
            type: 'span', sort: false,
            elements: {
              valor_item_carrinho: {
                // value: '${utils.money(@this.valor_item_carrinho)}',
                value: pedido.exibir_centavos ? '${utils.money(@this.valor_item_carrinho)}' : '${utils.moneyCents(@this.valor_item_carrinho)}',
                type: 'span', format: 'money', 
                bold: true
              },
              valor_dolar: {
                value: "${utils.customMoney('$',@this.valor_custo)}",
                rendered: '${@this.exibir_custo_dolar}',
                type: 'span',
                bold: true
              }
            }
          },
          valor_spv: { rendered: '${@this.exibir_spv}', label: "SPV", format: 'money', bold: true, sort: false },
          remove: {
            type: 'icon',
            icon: 'fa fa-trash', sort: false,
            click: {
              url: 'cart/delProduct/{id_sku}',
              method: 'GET',
              complete: refreshPedido
            }
          }
        }
      }
    }
  };

  const itensSimples = {
    name: 'itens',
    type: 'list',
    layout: 'table',
    controls: false,
    search: false,
    size: 500,
    add: false,
    list: {
      url: 'listarItensCarrinho?id_pedido={id_pedido}',
      send: false
    },
    columns: {
      avatar: { upload: false, type: 'img' },
      info: {
        label: "Descrição", sort: false,
        elements: {
          descricao: { field: 'descricao', type: 'span' },
          detalhe: { field: 'detalhe', type: 'span', sort: false },
        }
      },
      quantidade: {
        label: 'Quantidade'
      },
      valor_item_carrinho: { label: "Valor", type: 'span', format: 'money', bold: true }
    }
  };

  const onChangeTerceiro = function (args) {
    pedido.retirado_por_terceiro = args.value;
    crud.refresh();
  }

  const timeline = {};


  if ('|ERRO_PAGAMENTO|ERRO_FATURAMENTO|CANCELADO|AGUARDANDO_CANCELAMENTO|PRE_VENDA_PENDENTE|'.indexOf(pedido.constante_status) < 0) {
    timeline.criado = { label: utils.lng('pedido_realizado', 'Pedido realizado'), date: pedido.dh_registro };
    timeline.pago = { label: utils.lng('pagamento_aprovado', 'Pagamento aprovado'), date: pedido.dh_pagamento };
    timeline.nota = { label: utils.lng('nf_emitida', 'Nota fiscal emitida'), date: pedido.dh_faturamento };
    timeline.enviado = { label: utils.lng('produto_enviado', 'Produto(s) <br> enviado / entregue'), date: pedido.dh_entrega };
  } else {
    timeline.erro = { label: pedido.descricao_status, date: null };
  }

  window.crud = crud;

  return (
    <View name="carrinho">
      {!(pedido.id_pedido > 0) &&
        <div className="ui-pagamento">
          <div className="ui-topo ui-shadow ui-border ui-white">
            <div className="ui-col ui-col-2 ui-padding ui-color-red ui-bold ui-item-select">
              {utils.lng('meu_carrinho', 'Meu carrinho')}
            </div>
          </div>
          <div className="ui-content">
            <div className="ui-shadow ui-timeline ui-white ui-grid ui-table">
              <div className="ui-col ui-tl-item ui-col-auto ui-padding ui-big-label ui-empty-cart">
                {empty &&
                  <>
                    <div className="ui-center ui-padding">{utils.lng('carrinho_vazio', 'Seu carrinho esta vazio')}</div>
                    <div className="ui-center ui-padding">
                      <a href="/carrinho">{utils.lng('ir_para_catalogo', 'Ir para o catálogo')}</a>
                    </div>
                  </>
                }
                {!empty &&
                  <>
                    <div className="ui-center ui-padding">{utils.lng('carregando', 'Carregando...')}</div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      }
      {pedido.id_pedido > 0 &&
        <div className="ui-pagamento">
          <div className="ui-topo ui-shadow ui-border ui-white">
            <div id="status_pedido" className="ui-col ui-col-4 ui-padding ui-color-red ui-bold ui-item-select">
              {pedido.descricao_status}
            </div>
          </div>
          <div className="ui-content">
            {pedido.constante_status !== 'COMPRANDO' &&
              <>
                <div id="status_pedido_numero" className="ui-big-label" style={{ with: '300px' }}>
                  {utils.lng('descricao_status', 'Status do pedido')} {pedido.numero_pedido}

                  <Element data={pedido} crud={crud} value={acoes_topo}></Element>
                </div>
                <div className="ui-shadow ui-timeline ui-white ui-grid ui-table">
                  {Object.entries(timeline).map((ob, index) => (
                    <div className={'ui-tl-' + ob[0] + ' ui-col ui-tl-item ui-col-auto ui-padding ' + (ob[1].date ? 'ui-tl-sel' : '')}>
                      <div className="ui-tl-date" >
                        {ob[1].date}
                      </div>
                      <div className="ui-tl-icon" >
                        <i className={!ob[1].date ? 'fa fa-circle' : 'fas fa-check-circle'}></i>
                      </div>
                      <div className="ui-tl-label" >
                        <span dangerouslySetInnerHTML={{ __html: ob[1].label }} ></span>
                      </div>
                      <div className="ui-tl-bar" ></div>
                    </div>
                  ))}
                </div>
                <div className="ui-big-label">
                  {utils.lng('resumo_pedido', 'Resumo do pedido')}
                </div>
                <div className="ui-shadow ui-white ui-grid ui-table">
                  <div className="ui-col ui-col-4 ui-padding">
                    <div id="data_do_pedido" className="ui-small-label">
                      Data do pedido:<br /> {pedido.dh_registro}
                    </div>
                  </div>
                  {pedido.link_nfe &&
                    <div className="ui-col ui-col-4 ui-padding">
                      <div id="chave_nfe" className="ui-small-label">
                        <i className="fa fa-file"></i>
                        <a style={{ textDecoration: 'underline', paddingTop: '15px', marginLeft: '15px' }} href={pedido.link_nfe} target="_blank">Ver nota fiscal</a>
                      </div>
                    </div>
                  }
                  <div className="ui-col ui-col-4 ui-border-left ui-padding">
                    <div className="ui-small-label">
                      {utils.lng('valor_total', 'Valor total do pedido: ')} <br /> {utils.money(pedido.valor_total)}
                    </div>
                  </div>
                </div>
                {pedido.cep &&
                  <>
                    <div className="ui-big-label">
                      {pedido.retirar_filial ? utils.lng('retirada', 'Retirada') : utils.lng('envio', 'Envio')}
                    </div>
                    <div className="ui-shadow ui-white ui-grid ui-table">
                      <div className="ui-col ui-col-6 ui-padding">
                        <div id="envio_do_pedido" className="ui-small-label">
                          {pedido.logradouro}, {pedido.bairro ? pedido.bairro : ''}, {pedido.numero}, {pedido.complemento}  <br /> {utils.lng('cep', 'CEP')}: {utils.appyMask(pedido.cep, '99.999-999')}, {pedido.cidade}-{pedido.estado}
                        </div>
                      </div>
                      {pedido.codigo_rastreio &&
                        <div className="ui-col ui-upper ui-col-6 ui-padding">
                          <div id="cod_rastreio" className="ui-small-label">
                            <b>{utils.lng('codigo_rastreio', 'Código de rastreio')} {pedido.descricao_entrega}: </b> <br />
                            {pedido.codigo_rastreio}
                          </div>
                        </div>
                      }
                    </div>
                  </>
                }

                <div className="ui-big-label">
                  {utils.lng('info_de_pagamento', 'Informações de pagamento')}
                </div>
                <div className="ui-resumo-minhas-formas">
                  {Object.entries(meusPagamentos).map((el, i) => (
                    <div id={"lista_pagamento_" + i} className="ui-shadow ui-white ui-grid ui-grid-pgtos ui-grid-meus-pgtos">
                      <>
                        <div className={'ui-col ui-col-1 ui-item-pgto ui-item-pgto-' + i}>
                          {i + 1}
                        </div>
                        <div className="ui-col ui-col-2 ui-padding">
                          <div className="ui-small-label">
                            Valor
                          </div>
                          <div id={"valor_pagamento_" + i} className="ui-value ui-bold ui-color-green ">
                            {utils.money(el[1].valor)}
                          </div>
                        </div>
                        <div id={"forma_pagamento_" + i} className="ui-col ui-col-6 ui-padding ui-bold ui-big" >
                          {el[1].nome}
                        </div>
                        <div className="ui-col ui-col-2 ui-padding ui-pg-info" >
                          {el[1].info &&
                            <div dangerouslySetInnerHTML={{ __html: el[1].info }} ></div>
                          }
                          {el[1].icone &&
                            <i className={el[1].icone}></i>
                          }
                        </div>
                      </>
                    </div>
                  ))
                  }
                </div>
                {pedido.infos &&
                  <>
                    {pedido.infos && pedido.infos.BOLETO_CODIGO_BARRAS &&
                      <>

                        <div className="ui-shadow ui-boleto ui-border ui-radius ui-center ui-white ui-grid ui-table">
                          <div className="ui-col ui-col-12 ui-padding">
                            <br />
                            <div>
                              <i className="fa fa-barcode"></i>
                            </div>
                            <div className="ui-barcode" id="barcode">
                              {pedido.infos.BOLETO_CODIGO_BARRAS}
                            </div>
                            <br />
                            <a id="copy_barcode" className="ui-button-link" onClick={() => { utils.copy('barcode') }}>
                              {utils.lng('copiar_numero_pagamento', 'COPIAR NÚMERO PARA PAGAMENTO')}
                            </a><br /><br />
                            <a className="ui-button-link" href={pedido.infos.BOLETO_URL} target="_blank">
                              {utils.lng('baixar_boleto', 'BAIXAR BOLETO')}
                            </a><br /><br />
                          </div>
                        </div>
                      </>
                    }
                  </>
                }
                <div className="ui-big-label">
                  {utils.lng('detalhes_pedido', 'Detalhes do pedido')}
                </div>
                <div className="ui-shadow ui-white ui-grid ui-table">
                  <div className="ui-col ui-col-12 ui-padding">
                    <Element value={itensSimples} crud={crud} data={pedido} view={view} ></Element>
                  </div>
                </div>
                <div id="totais_do_pedido" style={{ marginTop: '20px' }} className="ui-card ui-shadow ui-card-resumo ui-white ui-nopadding ui-theme">
                  <div className="ui-card-title">
                    {utils.lng('valor_total', 'Totais do pedido')}
                  </div>
                  <div className="ui-card-data">
                    <div className=" ui-white ui-grid">
                      <div id="valor_dos_itens" className="ui-col ui-col-12 ui-padding">
                        <div className="ui-small-label">
                          {utils.lng('valor', 'Valor dos itens')}
                        </div>
                        <div id="valor_produtos" className="ui-value ui-bold ui-color-blue">
                          {pedido.exibir_centavos ? utils.money(pedido.valor_produtos) : utils.moneyCents(pedido.valor_produtos)}
                        </div>
                      </div>
                      {pedido.exibir_custo_dolar &&
                        <>
                          <div className="ui-col ui-col-12 ui-padding">
                            <div className="ui-small-label">
                              {utils.lng('dolar', 'Dolar')}
                            </div>
                            <div id="valor_dolar" className="ui-value ui-bold ui-color-blue">
                              {utils.customMoney('$', pedido.valor_custo)}
                            </div>
                          </div>
                        </>
                      }
                      {pedido.exibir_spv &&
                        <>
                          <div className="ui-col ui-col-12 ui-padding">
                            <div className="ui-small-label">
                              {utils.lng('valor_spv', 'SPV')}
                            </div>
                            <div id="valor_spv" className="ui-value ui-bold ui-color-blue">
                              {utils.money(pedido.valor_spv)}
                            </div>
                          </div>
                        </>
                      }
                      <div className="ui-col ui-col-12 ui-padding">
                        <div className="ui-small-label">
                          {utils.lng('frete', 'Frete')}
                        </div>
                        <div id="valor_frete" className="ui-value ui-bold ui-color-green">
                          {pedido.valor_frete <= 0 &&
                            <>
                              {utils.lng('gratis', 'Grátis')}
                            </>
                          }
                          {pedido.valor_frete > 0 &&
                            <>
                              {utils.money(pedido.valor_frete)}
                            </>
                          }
                        </div>
                      </div>
                      <div className="ui-col ui-col-12 ui-padding">
                        <div className="ui-small-label">
                          {utils.lng('descontos', 'Descontos')}
                        </div>
                        <div id="valor_desconto" className="ui-value ui-bold ui-color-red">
                          {utils.money(pedido.valor_descontos)}
                        </div>
                      </div>
                      <div className="ui-col ui-col-12 ui-padding">
                        <div className="ui-small-label">
                          {utils.lng('valor_pedido', 'Valor Total')}
                        </div>
                        <div id="valor_total_pedido" className="ui-value ui-bold ui-color-green ">
                          {utils.money(pedido.valor_total)}
                        </div>
                      </div>
                      <div className="ui-col ui-col-12 ui-padding">
                        <div className="ui-small-label">
                          {utils.lng('pontos_pedido', 'Pontos do pedido')}
                        </div>
                        <div id="pontos_pedido" className="ui-value ui-bold ui-color-blue">
                          {
                            pedido.pontos_pedido
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="anotacoes_do_pedido" style={{ marginTop: '20px' }} className="ui-card ui-shadow ui-card-resumo ui-white ui-nopadding ui-theme">
                  <div className="ui-card-title">
                    {utils.lng('anotacoes_pedido', 'Anotações do pedido')}
                  </div>
                  {pedido.id_pedido > 0 &&
                    <>
                      <Anotacao add={true} id={pedido.id_pedido} entidade='HubPedidoVenda' view={view}></Anotacao>
                    </>
                  }
                </div>
              </>
            }
            {pedido.constante_status === 'COMPRANDO' &&
              <>
                <div className="ui-big-label">
                  {utils.lng('nome_cliente', 'Cliente')}
                </div>
                <div className="ui-shadow ui-white ui-grid ui-table">
                  <div className="ui-col ui-col-3 ui-padding client-input">
                    <Element value={cliente} crud={crud} data={pedido} view={view} ></Element>
                  </div>
                  <div className="ui-col ui-col-2 ui-border-left ui-padding cod-mund">
                    <div id="codigo_mundial" className="ui-small-label">
                      {utils.lng('cod_mundial', 'Cód. Mundial')}:<br /> {pedido.codigo_mundial}
                    </div>
                  </div>
                  <div className="ui-col ui-col-2 ui-border-left ui-padding client-ped">
                    <div id="pedido_cidade" className="ui-small-label">
                      {utils.lng('cidade_ped', 'Cidade')}:<br /> {pedido.cidade} / {pedido.estado}
                    </div>
                  </div>
                  <div className="ui-col ui-col-3 ui-border-left ui-padding">
                    <Element disabled={false} value={filial} crud={crud} data={pedido} view={view} ></Element>
                  </div>
                </div>
                <div className="ui-big-label">
                  {utils.lng('pedido', 'Pedido')}
                </div>
                <div id="informacoes_pedido" className="ui-shadow ui-white ui-grid ui-table">
                  <div className="ui-col ui-col-12 ui-padding">
                    <Element value={itens} crud={crud} data={pedido} view={view} ></Element>
                  </div>
                </div>
                <div className="ui-big-label">

                </div>
                <div className="ui-grid ui-table">
                  <div className="ui-col ui-bloco-entrega ui-col-8">
                    {(user.tipo+'').indexOf('ADMINISTRATIVO') > -1 &&
                      <>
                        <div className="ui-card ui-shadow ui-white ui-nopadding" style={{ marginBottom: '20px' }}>
                          <div className="ui-card-title">
                            Qual o tipo do pedido?
                          </div>
                          <div className="ui-card-data">
                            <div id="tipos_pedido" className="ui-col ui-col-12">
                              {tipos_pedido.map((i, index) => (
                                <>
                                  <div onClick={() => { selecionarTipoVenda(i) }} style={{ padding: '10px 20px' }}
                                    className={'ui-tipo-venda ui-pointer ui-col ui-col-12 ' + (index % 2 === 0 ? 'ui-row-event' : 'ui-row-odd')}>
                                    <Toggle value={i.constante === pedido.tipo_pedido} label={false}></Toggle>
                                    <span className="ui-padding-left">{i.descricao}</span>
                                  </div>
                                </>
                              ))
                              }
                            </div>
                          </div>
                        </div>
                      </>
                    }
                    <>
                      {
                        pedido.quantidade > 0 &&
                        <>
                          <div className="ui-card ui-shadow ui-white ui-nopadding">
                            <div className="ui-card-title">
                              {utils.lng('seleciona_forma_entrega', 'Selecionar forma de entrega')}
                            </div>
                            <div className="ui-card-data">
                              <div className="ui-col ui-col-12">
                                {formasEntrega.map((i, index) => (
                                  <>
                                    {i.indisponivel !== true &&
                                      <>
                                        <div id={"forma_entrega_" + index} onClick={() => { selecionarEntrega(i) }}
                                          className={'ui-forma-entrega ui-pointer ui-col ui-col-12 ' + (index % 2 === 0 ? 'ui-row-event' : 'ui-row-odd') + (i.indisponivel ? ' ui-indisponivel ' : '')}>
                                          <Toggle value={i.id_entrega === pedido.id_entrega} label={false}></Toggle>
                                          <span className="ui-padding-left">{i.descricao}</span>
                                          <span className="ui-float-left ui-fix-right">
                                            <span className="ui-previsao-frete">{i.previsao ? i.previsao : ' '}</span>
                                            <span className="ui-valor-frete ui-bold">
                                              {utils.money(i.valor_frete)}
                                            </span>
                                          </span>
                                          {i.erro &&
                                            <div className="ui-erro-frete">{i.erro}</div>
                                          }
                                        </div>
                                        {i.id_entrega === pedido.id_entrega &&
                                          <>
                                            {pedido.constante_entrega === 'RETIRAR_FILIAL' &&
                                              <>
                                                <div className="ui-col ui-col-12  ui-terceiros ui-padding">
                                                  <div className="ui-terceiros-data">
                                                    <div className="ui-label ui-bold">{utils.lng('quem_vai_retirar', 'Quem vai retirar?')}</div><br />
                                                    <Element value={terceiro} crud={crud} data={pedido} view={view} ></Element>
                                                  </div>
                                                </div>
                                              </>
                                            }
                                            {pedido.constante_entrega !== 'RETIRAR_FILIAL' &&
                                              <>
                                                <div className="ui-col ui-col-12  ui-light ui-padding">
                                                  <Element value={endereco} crud={crud} data={pedido} view={view} ></Element>
                                                </div>
                                              </>
                                            }
                                          </>
                                        }
                                      </>
                                    }
                                  </>
                                ))
                                }
                              </div>
                            </div>
                          </div>
                        </>
                      }
                    </>
                  </div>
                  <div className="ui-col ui-col-4">
                    {pedido.permite_desconto && pedido.quantidade > 0 &&
                      <div id="descontos_pedido" style={{ marginBottom: '20px' }} className="ui-card ui-shadow ui-card-resumo ui-white ui-nopadding ui-theme">
                        <div className="ui-card-title">
                          Descontos
                        </div>
                        <div className="ui-card-data">
                          <div className=" ui-white ui-grid">

                            <div id="desconto_dos_itens" className="ui-col ui-col-12 ui-padding">
                              <div className="ui-small-label">
                                Desconto dos itens
                              </div>
                              <div className="ui-value ui-bold ui-color-blue">
                                <table>
                                  <tr>
                                    <td>
                                      <CurrencyTextField autoComplete="new-password"
                                        defaultValue='' variant='outlined'
                                        currencySymbol='%'
                                        decimalCharacter=','
                                        digitGroupSeparator='.'
                                        value={descontos.percentual_desconto_pedido}
                                        onChange={(event, vl) => onChangePercentualDesconto('valor_produtos', 'valor_desconto_pedido', vl)} >
                                      </CurrencyTextField>
                                    </td>
                                    <td>
                                      <CurrencyTextField autoComplete="new-password"
                                        defaultValue='' variant='outlined'
                                        currencySymbol={utils.currencySymbol()}
                                        decimalCharacter=','
                                        digitGroupSeparator='.'
                                        value={descontos.valor_desconto_pedido}
                                        onChange={(event, vl) => onChangeDesconto('valor_desconto_pedido', vl)} >
                                      </CurrencyTextField>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div id="desconto_spv" className="ui-col ui-col-12 ui-padding">
                              <div className="ui-small-label">
                                Desconto SPV
                              </div>
                              <div className="ui-value ui-bold ui-color-blue">
                                <table>
                                  <tr>
                                    <td>
                                      <CurrencyTextField autoComplete="new-password"
                                        defaultValue='' variant='outlined'
                                        currencySymbol='%'
                                        decimalCharacter=','
                                        digitGroupSeparator='.'
                                        value={descontos.percentual_desconto_spv}
                                        onChange={(event, vl) => onChangePercentualDesconto('valor_spv', 'valor_desconto_spv', vl)} >
                                      </CurrencyTextField>
                                    </td>
                                    <td>
                                      <CurrencyTextField autoComplete="new-password"
                                        defaultValue='' variant='outlined'
                                        currencySymbol={utils.currencySymbol()}
                                        decimalCharacter=','
                                        digitGroupSeparator='.'
                                        value={descontos.valor_desconto_spv}
                                        onChange={(event, vl) => onChangeDesconto('valor_desconto_spv', vl)} >
                                      </CurrencyTextField>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div id="confirma_desconto" className="ui-col ui-col-12 ui-padding noborder">
                              <div className="ui-small-label"></div>
                              <div className="ui-value ui-bold ui-color-blue">
                                <Button onClick={aplicarDescontos} variant="contained">APLICAR DESCONTOS</Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    {
                      pedido.quantidade > 0 &&
                      <>
                        <div id="resumo_pedido" className="ui-card ui-shadow ui-card-resumo ui-white ui-nopadding ui-theme">
                          <div className="ui-card-title">
                            {utils.lng('resumo_pedido', 'Resumo do pedido')}
                          </div>
                          <div className="ui-card-data">
                            <div className=" ui-white ui-grid">
                              <div id="valor_dos_itens" className="ui-col ui-col-12 ui-padding">
                                <div className="ui-small-label">
                                  {utils.lng('valor_itens', 'Valor dos itens')}
                                </div>
                                <div id="valor_produtos" className="ui-value ui-bold ui-color-blue">
                                  {pedido.exibir_centavos ? utils.money(pedido.valor_produtos) : utils.moneyCents(pedido.valor_produtos)}
                                </div>
                              </div>
                              {pedido.exibir_spv &&
                                <>
                                  <div className="ui-col ui-col-12 ui-padding">
                                    <div className="ui-small-label">
                                      {utils.lng('valor_spv', 'SPV')}
                                    </div>
                                    <div id="valor_spv" className="ui-value ui-bold ui-color-blue">
                                      {pedido.exibir_centavos ? utils.money(pedido.valor_spv) : utils.moneyCents(pedido.valor_spv)}
                                    </div>
                                  </div>
                                </>
                              }
                              {pedido.exibir_custo_dolar &&
                                <>
                                  <div className="ui-col ui-col-12 ui-padding">
                                    <div className="ui-small-label">
                                      {utils.lng('dolar', 'Dolar')}
                                    </div>
                                    <div id="valor_dolar" className="ui-value ui-bold ui-color-blue">
                                      {utils.customMoney('$', pedido.valor_custo)}
                                    </div>
                                  </div>
                                </>
                              }
                              <div className="ui-col ui-col-12 ui-padding">
                                <div className="ui-small-label">
                                  {utils.lng('frete_label', 'Frete')}
                                </div>
                                <div id="valor_frete" className="ui-value ui-bold ui-color-green">
                                  {pedido.valor_frete <= 0 &&
                                    <>
                                      {utils.lng('gratis', 'Grátis')}
                                    </>
                                  }
                                  {pedido.valor_frete > 0 &&
                                    <>
                                      {pedido.exibir_centavos ? utils.money(pedido.valor_frete) : utils.moneyCents(pedido.valor_frete)}
                                    </>
                                  }
                                </div>
                              </div>
                              <div className="ui-col ui-col-12 ui-padding">
                                <div className="ui-small-label">
                                  {utils.lng('descontos', 'Descontos')}
                                </div>
                                <div id="valor_desconto" className="ui-value ui-bold ui-color-red">
                                  {pedido.exibir_centavos ? utils.money(pedido.valor_descontos) : utils.moneyCents(pedido.valor_descontos)}
                                </div>
                              </div>
                              <div className="ui-col ui-col-12 ui-padding">
                                <div className="ui-small-label">
                                  {utils.lng('valor_total', 'Valor total')}
                                </div>
                                <div id="valor_total_pedido" className="ui-value ui-bold ui-color-green ">
                                  {pedido.exibir_centavos ? utils.money(pedido.valor_total) : utils.moneyCents(pedido.valor_total)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                  </div>
                </div>
                <div className="ui-finalizar">
                  <Element value={confirmar} crud={crud} data={pedido} view={view} ></Element>
                </div>
              </>
            }
          </div>
        </div>
      }
    </View>
  );
}

export default Pedido;
